import { WppIconApp, WppIconExternalLink, WppListItem } from '@platform-ui-kit/components-library-react'
import { MayBeNull, NoCodeAppType } from '@wpp-open/core'
import { useNavigate } from 'react-router-dom'

import { LaunchLocations } from 'constants/analytics'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { DevHubAppVersion } from 'types/apps/devHubApps'
import { trackAppOpenAnalytics } from 'utils/analytics'
import { AppInstanceApp } from 'utils/appInstances'
import { routesManager } from 'utils/routesManager'

interface Props {
  isChecked: boolean
  app: AppInstanceApp
  customAppName?: MayBeNull<string>
  version?: DevHubAppVersion
}

export const BreadcrumbMenuAppItem = ({ isChecked, app, customAppName, version }: Props) => {
  const navigate = useNavigate()
  const { navigationTreeWithHiddenLevel } = useCurrentTenantData()
  const { activeHierarchyWithHiddenLevel } = useHeaderNavigation()
  const { userDetails } = useOtherTenantsAndUserData()
  const { appData } = useOsState()
  const { project, projectPhase, projectItem } = appData

  const appName = customAppName || app.name

  const isNoCodeApp = Object.values<string>(NoCodeAppType).includes(app.type)

  const handleTrackAnalytics = () => {
    trackAppOpenAnalytics({
      activeHierarchyWithHiddenLevel,
      navigationTreeWithHiddenLevel,
      productName: appData.appInstance?.devhubMetadata.name || '',
      productType: app.type,
      appId: app.stableId,
      userDetails,
      versionId: !isNoCodeApp ? version?.id || '' : '',
      versionName: !isNoCodeApp ? version?.name || '' : '',
      versionType: !isNoCodeApp ? version?.versionType || '' : '',
      project,
      projectItem,
      projectPhase,
      launchedFrom: LaunchLocations.Breadcrumbs,
    })
  }

  if (app.type === NoCodeAppType.ExternalLink) {
    return (
      <WppListItem
        linkConfig={{
          target: '_blank',
          rel: 'noreferrer',
          href: app.url,
        }}
        onWppChangeListItem={() => handleTrackAnalytics()}
        data-testid="breadcrumbs-menu-app-item"
      >
        <WppIconApp slot="left" />
        <span slot="label">{appName}</span>
        <WppIconExternalLink slot="right" />
      </WppListItem>
    )
  }

  const href = routesManager.app({
    id: app.stableId,
    osRoute: app.osRoute,
  })

  return (
    <WppListItem
      checked={isChecked}
      linkConfig={{
        href,
        onClick: e => e.preventDefault(),
      }}
      onWppChangeListItem={() => {
        if (!isChecked) {
          handleTrackAnalytics()
          navigate(href)
        }
      }}
      data-testid="breadcrumbs-menu-app-item"
    >
      <WppIconApp slot="left" />
      <span slot="label">{appName}</span>
    </WppListItem>
  )
}
